import React from 'react';

import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Header from '../partial/Header';
import Seo from '../components/Seo';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "errorBg.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    }
  }
`);

  return (
    <div className="font-Nunito">
      <Seo title="404: Not found" />
      <Header />
      <section className="min-w-screen min-h-screen flex items-start md:items-center px-5 py-[50px] lg:p-20 overflow-hidden relative">
        <div className="flex-1 min-h-full min-w-full py-10 px-0 md:p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-1 md:gap-20 2xl:grid-cols-2 lg:grid-cols-2 gap-8 items-center justify-items-center">
            <div className="w-full mt-10">
              <div className="mb-6 md:mb-6 text-gray-600 font-light">
                <h2 className="uppercase text-[24px] text-left md:text-3xl font-extrabold lg:text-5xl text-heading mb-6">You seem to be lost!</h2>
                <p className="w-ful md:w-[60%] text-left text-16md md:text-2md text-text pr-0 font-normal">The page you&apos;re looking for isn&apos;t available. Try searching again or use the Go Back button below.</p>
              </div>
              <div className="mb-6 md:mb-0">
                <div className="flex md:flex-none transform transition duration-500">
                  <Link to="/" rel="noreferrer" aria-label="location" className="cursor-pointer xl:px-10 md:px-10 md:py-4 px-6 py-2 bg-heading hover:bg-primary inline-flex rounded-full items-center justify-center font-medium text-white transition-colors duration-150 transform">
                    Go home
                  </Link>
                </div>
              </div>
            </div>
            <div className="relative inline-flex flex-col cursor-pointer mb-4 h-full object-contain">
              <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="waychit logo" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFoundPage;
